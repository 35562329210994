var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showDeleteNotifyModal
        ? _c("DeleteGroupNotification", {
            attrs: {
              show: _vm.showDeleteNotifyModal,
              currentNotification: _vm.currentNotification,
            },
            on: {
              close: function ($event) {
                _vm.showDeleteNotifyModal = false
              },
              deleteNotification: _vm.deleteNotification,
            },
          })
        : _vm._e(),
      _vm.showInfoModal
        ? _c("NotificationInfoModal", {
            attrs: {
              show: _vm.showInfoModal,
              inLkList: "",
              currentNotification: _vm.currentNotification,
            },
            on: {
              editSignal: _vm.editSignal,
              delete: _vm.showDeleteNotificationModal,
              recreateNotification: _vm.recreateNotification,
              close: function ($event) {
                _vm.showInfoModal = false
              },
              update: _vm.getHooks,
            },
          })
        : _vm._e(),
      _c("ConfirmModal", {
        attrs: { show: _vm.showConfirmModal, info: _vm.confirmModalInfo },
        on: { close: _vm.closeConfirmModal, confirm: _vm.confirmHandler },
      }),
      _c("hook-modal", {
        attrs: {
          currentHook: new _vm.HookClass({
            ...(_vm.currentHook?.hookObject || _vm.currentHook),
            editMode: true,
          }),
          show: _vm.showCreateHookModal,
          "is-futures": _vm.currentNotification?.isFutures || false,
        },
        on: { saveHook: _vm.saveHook, close: _vm.closeHookModal },
      }),
      _c("div", { staticClass: "hooksTable__controls" }, [
        _c(
          "div",
          { staticClass: "hooksTable__filter" },
          [
            _c("span", [_vm._v(" " + _vm._s(_vm.$t("general.show")) + ": ")]),
            _c("CSelect", {
              staticClass: "hooksTable__filter-select",
              attrs: {
                value: _vm.filterActive,
                placeholder: _vm.$t("screener.treemap.parameter"),
                options: _vm.filterActiveOptions,
              },
              on: {
                "update:value": function ($event) {
                  _vm.filterActive = $event
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "CCard",
        {
          staticClass: "ccard shadow-none overflow-hidden",
          attrs: { color: "second-card-bg" },
        },
        [
          _c(
            "CCardBody",
            { staticClass: "ccard-body d-flex flex-column overflow-hidden" },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-content-between mb-4" },
                [
                  _c("h5", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("lk.notifications.hooks")) + " "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _vm.filterActive
                        ? _c(
                            "CButton",
                            {
                              staticClass: "cbtn px-4 d-flex mr-2",
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("createHook")
                                },
                              },
                            },
                            [
                              _c("CIcon", { attrs: { name: "cil-plus" } }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("lk.notifications.template")) +
                                  " "
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          staticClass:
                            "popover-wrap d-inline-flex justify-content-center ml-2",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("toHowItWorks")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("general.howItWorks")) + "? "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm.tableData.length && !_vm.loading
                ? [
                    _c("CDataTable", {
                      staticClass: "c-data-table h-100 overflow-hidden",
                      attrs: {
                        sorter: "",
                        fields: _vm.fields,
                        items: _vm.tableData,
                        "sort-by": _vm.sortBy,
                        "sort-desc": _vm.sortDesc,
                      },
                      on: { "filtered-items-change": _vm.tableSortHandler },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "name",
                            fn: function ({ item }) {
                              return [
                                _c("td", [
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c("span", { staticClass: "mr-2" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item?.hookObject?.name || item.name
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "signalName",
                            fn: function ({ item }) {
                              return [
                                _c("td", [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "table-link table-link-hover",
                                      on: {
                                        click: function ($event) {
                                          return _vm.showSignal(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            !item.is_single ? item.name : "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "url",
                            fn: function ({ item }) {
                              return [
                                _c("td", [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item?.hookObject?.url || item.url
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "json",
                            fn: function ({ item }) {
                              return [
                                _c("td", [
                                  _c("pre", { staticClass: "hook-json" }, [
                                    _vm._v(
                                      _vm._s(
                                        item?.hookObject?.json || item.json
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "actions",
                            fn: function ({ item, index }) {
                              return [
                                _c(
                                  "td",
                                  { staticClass: "text-right text-nowrap" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        !_vm.filterActive
                                          ? _c("CSwitch", {
                                              staticClass: "switch-custom mr-3",
                                              attrs: {
                                                color: "primary",
                                                shape: "pill",
                                                checked: item.hookObject.enable,
                                                id: "switch",
                                              },
                                              on: {
                                                "update:checked": [
                                                  function ($event) {
                                                    return _vm.$set(
                                                      item.hookObject,
                                                      "enable",
                                                      $event
                                                    )
                                                  },
                                                  function ($event) {
                                                    return _vm.toggleHookEnable(
                                                      item,
                                                      index
                                                    )
                                                  },
                                                ],
                                              },
                                            })
                                          : _vm._e(),
                                        _c(
                                          "CButton",
                                          {
                                            staticClass: "mr-2",
                                            attrs: { color: "success" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editHook(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("CIcon", {
                                              attrs: { name: "cil-pencil" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "CLoadingButtonCustom",
                                          {
                                            attrs: {
                                              color: "danger",
                                              loading: _vm.checkLoadingId(
                                                item.is_single
                                                  ? item.marketplace_signal_id
                                                  : item.id
                                              ),
                                              disabled: _vm.checkLoadingId(
                                                item.is_single
                                                  ? item.marketplace_signal_id
                                                  : item.id
                                              ),
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.deleteHook(item)
                                              },
                                            },
                                          },
                                          [
                                            _c("CIcon", {
                                              attrs: { name: "cil-trash" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3511728915
                      ),
                    }),
                  ]
                : _c("p", { staticClass: "m-0" }, [
                    _vm._v(_vm._s(_vm.$t("lk.notifications.emptyActiveTable"))),
                  ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }