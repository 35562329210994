var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CandlesGraphModal", {
        ref: "candles",
        attrs: {
          clientId: _vm.clientId,
          rows: _vm.rows,
          settings: _vm.settings,
          isCoinDuplicate: true,
          isFutures: _vm.candleFuturesFlag,
        },
      }),
      _c(
        "CCard",
        {
          staticClass: "ccard shadow-none overflow-hidden",
          attrs: { color: "second-card-bg" },
        },
        [
          _c(
            "CCardBody",
            { staticClass: "ccard-body d-flex flex-column overflow-hidden" },
            [
              _c(
                "div",
                { staticClass: "mb-4 d-flex align-items-center" },
                [
                  _c("h5", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lk.notifications.activeListTitle")) +
                        " "
                    ),
                  ]),
                  _c("CSelect", {
                    staticClass: "mb-0 ml-3",
                    attrs: {
                      value: _vm.typeActive,
                      placeholder: _vm.$t("screener.treemap.parameter"),
                      options: _vm.typeOptions,
                    },
                    on: {
                      "update:value": function ($event) {
                        _vm.typeActive = $event
                      },
                      change: _vm.getNotifications,
                    },
                  }),
                ],
                1
              ),
              _vm.activeItems && _vm.activeItems.length
                ? [
                    _c("CDataTable", {
                      staticClass: "c-data-table h-100 overflow-hidden",
                      attrs: {
                        sorter: "",
                        fields: _vm.fields,
                        items: _vm.activeItems,
                        "sort-by": _vm.sortBy,
                        "sort-desc": _vm.sortDesc,
                      },
                      on: {
                        "filtered-items-change": _vm.tableSortHandler,
                        "row-clicked": _vm.rowClicked,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "name",
                            fn: function ({ item }) {
                              return [
                                _c("td", [
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c("span", { staticClass: "mr-2" }, [
                                      _vm._v(
                                        " " + _vm._s(item.pair.label) + " "
                                      ),
                                    ]),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: {
                                              content:
                                                _vm.$t("screener.openGraph"),
                                              container: ".c-app",
                                            },
                                            expression:
                                              "{\n                    content: $t('screener.openGraph'),\n                    container: '.c-app',\n                  }",
                                          },
                                        ],
                                        staticClass: "cil-bar-chart-btn",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.openCandlesGraph(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("CIcon", {
                                              staticClass: "cursor-pointer",
                                              attrs: {
                                                width: "0.75rem",
                                                name: "cil-bar-chart",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "is_futures",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "td",
                                  [
                                    _c("exchange-logo", {
                                      attrs: { "is-futures": item.is_futures },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                          {
                            key: "conditions",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "td",
                                  _vm._l(item.conditions, function (v, i) {
                                    return _c(
                                      "p",
                                      { key: i, staticClass: "m-0" },
                                      [
                                        v.tf !== "price"
                                          ? _c(
                                              "span",
                                              { staticClass: "mr-1" },
                                              [_vm._v(" " + _vm._s(v.tf) + " ")]
                                            )
                                          : _vm._e(),
                                        _c("span", { staticClass: "mr-1" }, [
                                          _vm._v(
                                            _vm._s(item.conditionName(v.param))
                                          ),
                                        ]),
                                        v.hasValue
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    v.isNegative ? v.sign : ""
                                                  ) +
                                                  _vm._s(v.value) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            },
                          },
                          {
                            key: "repeatableObj",
                            fn: function ({ item }) {
                              return [
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.repeatableObj.labelI18n
                                          ? _vm.$t(item.repeatableObj.labelI18n)
                                          : item.repeatableObj.label
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "unixCreatedDate",
                            fn: function ({ item }) {
                              return [
                                _c("td", [
                                  _vm._v(" " + _vm._s(item.createdAt) + " "),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "actions",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "td",
                                  { staticClass: "text-right text-nowrap" },
                                  [
                                    _c(
                                      "CButton",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { color: "success" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.editSignal(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("CIcon", {
                                          attrs: { name: "cil-pencil" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "CLoadingButtonCustom",
                                      {
                                        attrs: {
                                          color: "danger",
                                          loading: _vm.checkLoadingId(item),
                                          disabled: _vm.checkLoadingId(item),
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.deleteItem(item, $event)
                                          },
                                        },
                                      },
                                      [
                                        _c("CIcon", {
                                          attrs: { name: "cil-trash" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        747275450
                      ),
                    }),
                  ]
                : _c("p", { staticClass: "m-0" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lk.notifications.emptyActiveTable")) +
                        " "
                    ),
                  ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }