var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notificationLogs" },
    [
      _c("CandlesGraphModal", {
        ref: "candles",
        attrs: { rows: _vm.rows, settings: _vm.settings, isCoinDuplicate: "" },
      }),
      _c("div", { staticClass: "notificationLogs__filter" }),
      _c(
        "CCard",
        {
          staticClass: "shadow-none notificationLogs__card",
          attrs: { color: "second-card-bg" },
        },
        [
          _c(
            "CCardBody",
            { staticClass: "h-100 d-flex flex-column" },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-content-between mb-4" },
                [
                  _c("h5", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("lk.notifications.hookLogs")) + " "
                    ),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass:
                        "popover-wrap d-inline-flex justify-content-center ml-2",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("toHowItWorks", "logs")
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("general.howItWorks")) + "? ")]
                  ),
                ]
              ),
              _c("div", { staticClass: "table-wrap" }, [
                !_vm.isLoading && _vm.tableData.length
                  ? _c(
                      "table",
                      { staticClass: "table mb-0 notificationLogs__table" },
                      [
                        _c("thead", [
                          _c(
                            "tr",
                            _vm._l(_vm.fields, function (field, i) {
                              return _c(
                                "th",
                                {
                                  key: i,
                                  class: field._classes,
                                  on: {
                                    click: function ($event) {
                                      field.sortBy
                                        ? _vm.sortTable(field.sortBy)
                                        : null
                                    },
                                  },
                                },
                                [
                                  _vm._v(" " + _vm._s(field.label) + " "),
                                  field.sortBy === _vm.orderBy
                                    ? _c("span", { key: _vm.sortDir }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.sortDir === "DESC" ? "▼" : "▲"
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.tableData, function (item, i) {
                            return _c("tr", { key: item.id + "_" + i }, [
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        item.created_at,
                                        "DD.MM.YYYY HH:mm"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c(
                                "td",
                                { staticClass: "position-relative pr-3" },
                                [
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c("span", { staticClass: "mr-2" }, [
                                      _vm._v(" " + _vm._s(item.pair) + " "),
                                    ]),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: {
                                              content:
                                                _vm.$t("screener.openGraph"),
                                              container: ".c-app",
                                            },
                                            expression:
                                              "{\n                    content: $t('screener.openGraph'),\n                    container: '.c-app',\n                  }",
                                          },
                                        ],
                                        staticClass: "cil-bar-chart-btn",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            _vm.$refs.candles.openCandlesGraph(
                                              item.pair.split(":")[1],
                                              2,
                                              item.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("CIcon", {
                                              staticClass: "cursor-pointer",
                                              attrs: {
                                                width: "0.75rem",
                                                name: "cil-bar-chart",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("td", [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(item.signal_message),
                                  },
                                }),
                              ]),
                              _c("td", [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        !item.is_single ? item.group_name : "-"
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("td", [
                                _c("span", [
                                  _vm._v(" " + _vm._s(item.hook.name) + " "),
                                ]),
                              ]),
                              _c("td", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "table-link table-link-hover",
                                    on: {
                                      click: function ($event) {
                                        return _vm.showRes(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.status_code) + " ")]
                                ),
                              ]),
                            ])
                          }),
                          0
                        ),
                      ]
                    )
                  : _vm._e(),
                !_vm.isLoading && !_vm.tableData.length
                  ? _c("p", { staticClass: "m-0" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("lk.notifications.emptyActiveTable")) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("CPagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.totalPages > 1,
                    expression: "totalPages > 1",
                  },
                ],
                staticClass: "mt-2 pagination-custom",
                class: { _disabled: _vm.isLoading },
                attrs: {
                  activePage: _vm.page,
                  pages: _vm.totalPages,
                  limit: 7,
                  dots: false,
                },
                on: {
                  "update:activePage": function ($event) {
                    _vm.page = $event
                  },
                  "update:active-page": function ($event) {
                    _vm.page = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }