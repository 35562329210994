var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "groupNotifications" },
    [
      _vm.showDeleteModal
        ? _c("DeleteGroupNotification", {
            attrs: {
              show: _vm.showDeleteModal,
              currentNotification: _vm.currentNotification,
            },
            on: {
              close: function ($event) {
                _vm.showDeleteModal = false
              },
              deleteNotification: _vm.deleteNotification,
            },
          })
        : _vm._e(),
      _vm.showInfoModal
        ? _c("NotificationInfoModal", {
            attrs: {
              show: _vm.showInfoModal,
              inLkList: "",
              currentNotification: _vm.currentNotification,
            },
            on: {
              delete: _vm.showDeleteNotificationModal,
              recreateNotification: _vm.recreateNotification,
              editSignal: _vm.editSignal,
              close: function ($event) {
                _vm.showInfoModal = false
              },
              update: _vm.getData,
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "groupNotifications__filter" },
        [
          _c("span", [_vm._v(" " + _vm._s(_vm.$t("general.show")) + ": ")]),
          _c("CSelect", {
            staticClass: "groupNotifications__filter-select",
            attrs: {
              value: _vm.filterActive,
              placeholder: _vm.$t("screener.treemap.parameter"),
              options: _vm.filterActiveOptions,
            },
            on: {
              "update:value": function ($event) {
                _vm.filterActive = $event
              },
              change: _vm.filterActiveChange,
            },
          }),
          _c("CSelect", {
            staticClass: "groupNotifications__type-select",
            attrs: {
              value: _vm.typeActive,
              placeholder: _vm.$t("screener.treemap.parameter"),
              options: _vm.typeOptions,
            },
            on: {
              "update:value": function ($event) {
                _vm.typeActive = $event
              },
              change: _vm.filterActiveChange,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "groupNotifications__list-wrpper" },
        [
          !_vm.loading && _vm.groupNotifications.length
            ? _vm._l(_vm.groupNotifications, function (item) {
                return _c("NotificationCardItem", {
                  key: item.id,
                  attrs: {
                    notificationData: item,
                    tokens: _vm.tokens,
                    inLkList: "",
                  },
                  on: {
                    updateList: _vm.getData,
                    delete: _vm.showDeleteNotificationModal,
                    updatePaused: function ($event) {
                      return _vm.$emit("updatePaused", _vm.groupNotifications)
                    },
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.notificationCardClick(item)
                    },
                  },
                })
              })
            : _vm._e(),
          !_vm.loading && !_vm.groupNotifications.length
            ? _c("p", { staticClass: "m-0" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("lk.notifications.emptyActiveTable")) +
                    " "
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }