<template>
  <div class="groupNotifications">
    <DeleteGroupNotification
    :show="showDeleteModal"
    :currentNotification="currentNotification"
    v-if="showDeleteModal"
    @close="showDeleteModal = false"
    @deleteNotification="deleteNotification"
    />
    <NotificationInfoModal
    :show="showInfoModal"
    @delete="showDeleteNotificationModal"
    @recreateNotification="recreateNotification"
    @editSignal="editSignal"
    inLkList
    :currentNotification="currentNotification"
    v-if="showInfoModal"
    @close="showInfoModal = false"
    @update="getData"
    />
    <div class="groupNotifications__filter">
      <span>
        {{ $t('general.show') }}:
      </span>
      <CSelect
        class="groupNotifications__filter-select"
        :value.sync="filterActive"
        :placeholder="$t('screener.treemap.parameter')"
        :options="filterActiveOptions"
        @change="filterActiveChange"
      />
      <CSelect
        class="groupNotifications__type-select"
        :value.sync="typeActive"
        :placeholder="$t('screener.treemap.parameter')"
        :options="typeOptions"
        @change="filterActiveChange"
      />
    </div>
    <div class="groupNotifications__list-wrpper">
      <template v-if="!loading && groupNotifications.length">
        <NotificationCardItem
        v-for="item in groupNotifications"
        :notificationData="item"
        @updateList="getData"
        @delete="showDeleteNotificationModal"
        @updatePaused="$emit('updatePaused',groupNotifications)"
        :key="item.id"
        :tokens="tokens"
        inLkList
        @click.native="notificationCardClick(item)"/>
      </template>
      <p
        v-if="!loading && !groupNotifications.length"
        class="m-0">
          {{ $t('lk.notifications.emptyActiveTable') }}
        </p>
    </div>
  </div>


</template>

<script>
import DeleteGroupNotification from '@/components/notifications/DeleteGroupNotification'
import NotificationCardItem from '@/components/marketplace/NotificationCardItem'
import NotificationInfoModal from '@/components/marketplace/NotificationInfoModal'
import Notification from "@/assets/js/notifications/Notification.class";
import axios from "axios";
export default {
  name: "GroupNotifications",
  components: {
    NotificationCardItem,
    NotificationInfoModal,
    DeleteGroupNotification
  },
  data() {
    return {
      filterActive: 1,
      filterActiveOptions: [
        {
          label: this.$t('lk.notifications.active'),
          value: 1
        },
        {
          label: this.$t('lk.notifications.inActive'),
          value: 0
        },
      ],
      typeActive: null,
      typeOptions: [
        {
          label: this.$t('lk.notifications.marketplace.types.all'),
          value: null
        },
        {
          label: this.$t('lk.notifications.marketplace.types.futures'),
          value: 1
        },
        {
          label: this.$t('lk.notifications.marketplace.types.spot'),
          value: 0
        },
      ],
      tokens: [],
      loading: false,
      showDeleteModal: false,
      showInfoModal: false,
      currentNotification: null,
      groupNotifications: [],
    }
  },
  created(){
    this.getNotifications()
    this.getTokens()
  },
  methods:{
    editSignal(signal){
      this.$emit('recreateNotification',signal)
      this.showInfoModal = false
    },
    recreateNotification(notification){
      this.$emit('recreateNotification',notification)
      this.showInfoModal = false
    },
    filterActiveChange(){
      this.getData()
    },
    deleteNotification(){
      axios.delete(`api/v1/signals/marketplace/${this.currentNotification.id}`)
      .then(({status}) => {
        if (status < 300) {
          this.$toastr.success(this.$t('lk.notifications.deleteNotificationNote'))
          this.getNotifications()
        }
      })
      .finally(() => {
        this.showDeleteModal = false
      })
    },
    showDeleteNotificationModal(val){
      this.currentNotification = val
      this.showDeleteModal = true
    },
    notificationCardClick(item){
      this.currentNotification = item
      this.showInfoModal = true
    },
    getData(){
      this.getNotifications()
      this.getTokens()
    },
    getNotifications(){
      this.loading = true
      this.groupNotifications = []
      this.$emit('update:loading', true)

      axios.get('api/v1/signals/group-list',{
        params: {
          is_active: this.filterActive,
          is_futures: this.typeActive,
        }
      })
        .then(({ status, data }) => {
          if (status < 300) {
            this.groupNotifications = data.data.map(v => new Notification(v))
          }
        })
      .finally(() => {
        this.loading = false
        this.$emit('setIsPaused', this.groupNotifications)
        this.$emit('update:loading', false)
      })
    },
    getTokens(){
      // this.loading = true
      const url = 'api/v2/marketplace-tokens/user'
      axios.get(url)
        .then(({status, data}) => {
          if (status < 300) {
            this.tokens = data.data
            this.$emit('setTokens',this.tokens)
          }
        })
        .finally(() => {
          // this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .groupNotifications{
    &__filter{
      display: flex;
      flex-wrap: wrap;
      width: fit-content;
      align-items: center;
      & span{
        margin-right: 8px;
        @media screen and (max-width: 512px) {
          margin-right: 0;
          width: 100%;
        }
      }
      @media screen and (max-width: 512px) {
        flex-direction: column;
        width: 100%;
      }
    }
    &__filter-select{
      margin-bottom: 0;
      @media screen and (max-width: 512px) {
        width: 100%;
        margin-top: 5px;
      }
    }
    &__type-select{
      margin-bottom: 0;
      margin-left: 10px;
      @media screen and (max-width: 512px) {
        width: 100%;
        margin-left: 0;
        margin-top: 8px;
      }
    }
    &__list-wrpper{
      margin-top: 16px;
      display: grid;
      gap: 12px;
      overflow: auto;
      padding-right: 5px;
      @include media-breakpoint-up(md){
        margin-top: 20px;
      }
      @include media-breakpoint-up(xl){
        margin-top: 20px;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
      }
      /deep/ .notificationCardItem{
        background: var(--second-card-bg) !important;
      }
    }
  }
</style>
