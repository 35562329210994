<template>
  <div class="groupNotifications">
    <DeleteGroupNotification
    :show="showDeleteModal"
    :currentNotification="currentNotification"
    v-if="showDeleteModal"
    @close="showDeleteModal = false"
    @deleteNotification="deleteNotification"
    />
    <NotificationInfoModal
    @delete="showDeleteNotificationModal"
    :show="showInfoModal"
    :currentNotification="currentNotification"
    v-if="showInfoModal"
    @update="getData"
    @close="showInfoModal = false"
    />
    <template v-if="!loading && groupNotifications.length">
      <NotificationCardItem
      v-for="item in groupNotifications"
      :notificationData="item"
      @delete="showDeleteNotificationModal"
      @updateList="getData"
      :tokens="tokens"
      :key="item.id"
      @click.native="notificationCardClick(item)"/>
    </template>
    <p
    v-if="!loading && !groupNotifications.length"
    class="m-0"
    >
      {{ $t('lk.notifications.emptyActiveTable') }}
    </p>
  </div>
</template>

<script>
import DeleteGroupNotification from '@/components/notifications/DeleteGroupNotification'
import NotificationCardItem from '@/components/marketplace/NotificationCardItem'
import NotificationInfoModal from '@/components/marketplace/NotificationInfoModal'
import Notification from "@/assets/js/notifications/Notification.class";
import axios from "axios";
export default {
  name: "AdminGroupNotifications",
  components: {
    NotificationCardItem,
    NotificationInfoModal,
    DeleteGroupNotification
  },
  data() {
    return {
      tokens: [],
      loading: false,
      showDeleteModal: false,
      showInfoModal: false,
      currentNotification: null,
      groupNotifications: [],
      mocNotification:{
        assigned: true,
        name: 'Публичное оповещение 1',
        coinsLength: 64,
        terms: "Объем в USDT вырос на 1M и Объем USDT Delta вырос на 50к",
        comment: "Текст описание публичного оповещения, текст описание публичного оповещения, текст описание публичного оповещения,",
        coins: ['BINANCE:BTC', 'BINANCE:ETH', 'BINANCE:1INCH', 'BINANCE:BAT1', 'BINANCE:BNB', 'BINANCE:BNX', 'BINANCE:ETH1', 'BINANCE:BTC1', 'BINANCE:1INCH1', 'BINANCE:BAT', 'BINANCE:BTC', 'BINANCE:ETH', 'BINANCE:1INCH', 'BINANCE:BAT1', 'BINANCE:BNB', 'BINANCE:BNX', 'BINANCE:ETH1', 'BINANCE:BTC1', 'BINANCE:1INCH1', 'BINANCE:BAT']
      }
    }
  },
  created(){
    this.getNotifications()
    this.getTokens()
  },
  methods:{
    getData(){
      this.getNotifications()
      this.getTokens()
    },
    deleteNotification(){
      axios.delete(`api/v1/signals/marketplace/${this.currentNotification.id}`)
      .then(({status}) => {
        if (status < 300) {
          this.$toastr.success(this.$t('lk.notifications.addSuccess'))
          this.getNotifications()
        }
      })
      .finally(() => {
        this.showDeleteModal = false
      })
    },
    showDeleteNotificationModal(val){
      this.currentNotification = val
      this.showDeleteModal = true
    },
    notificationCardClick(item){
      this.currentNotification = item
      this.showInfoModal = true
    },
    getNotifications(){
      this.loading = true
      this.$emit('update:loading', true)

      axios.get('api/v1/signals/marketplace')
        .then(({ status, data }) => {
          if (status < 300) {
            this.groupNotifications = data.data.map(v => new Notification(v))
          }
        })
      .finally(() => {
        this.loading = false
        this.$emit('update:loading', false)
      })
    },
    getTokens(){
      this.loading = true
      const url = 'api/v2/marketplace-tokens/user'
      axios.get(url)
        .then(({status, data}) => {
          if (status < 300) {
            this.tokens = data.data
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .groupNotifications{
    margin-top: 16px;
    display: grid;
    gap: 12px;
    overflow: auto;
    padding-right: 5px;
    @include media-breakpoint-up(md){
      margin-top: 20px;
    }
    @include media-breakpoint-up(xl){
      margin-top: 20px;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
    /deep/ .notificationCardItem{
      background: var(--second-card-bg) !important;
    }
  }
</style>
