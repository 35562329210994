var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "groupNotifications" },
    [
      _vm.showDeleteModal
        ? _c("DeleteGroupNotification", {
            attrs: {
              show: _vm.showDeleteModal,
              currentNotification: _vm.currentNotification,
            },
            on: {
              close: function ($event) {
                _vm.showDeleteModal = false
              },
              deleteNotification: _vm.deleteNotification,
            },
          })
        : _vm._e(),
      _vm.showInfoModal
        ? _c("NotificationInfoModal", {
            attrs: {
              show: _vm.showInfoModal,
              currentNotification: _vm.currentNotification,
            },
            on: {
              delete: _vm.showDeleteNotificationModal,
              update: _vm.getData,
              close: function ($event) {
                _vm.showInfoModal = false
              },
            },
          })
        : _vm._e(),
      !_vm.loading && _vm.groupNotifications.length
        ? _vm._l(_vm.groupNotifications, function (item) {
            return _c("NotificationCardItem", {
              key: item.id,
              attrs: { notificationData: item, tokens: _vm.tokens },
              on: {
                delete: _vm.showDeleteNotificationModal,
                updateList: _vm.getData,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.notificationCardClick(item)
                },
              },
            })
          })
        : _vm._e(),
      !_vm.loading && !_vm.groupNotifications.length
        ? _c("p", { staticClass: "m-0" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("lk.notifications.emptyActiveTable")) + " "
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }