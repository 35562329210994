var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "CModal",
        {
          staticClass: "hook-res-modal",
          attrs: { centered: "", show: _vm.show },
          on: {
            "update:show": function ($event) {
              return _vm.$emit("close")
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "hook-res-modal__header" },
                      [
                        _c("h5", { staticClass: "hook-res-modal__title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.currentRes.status_code) + " "
                          ),
                        ]),
                        _c("CIcon", {
                          staticClass: "hook-res-modal__header-close",
                          attrs: { name: "cil-x" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.closeHookResModal.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "footer-wrapper",
                fn: function () {
                  return [_c("span")]
                },
                proxy: true,
              },
            ],
            null,
            false,
            704502564
          ),
        },
        [
          _c("CTextarea", {
            staticClass: "mb-0 w-100",
            attrs: { disabled: "", rows: "10" },
            model: {
              value: _vm.currentRes.response_body,
              callback: function ($$v) {
                _vm.$set(_vm.currentRes, "response_body", $$v)
              },
              expression: "currentRes.response_body",
            },
          }),
          _c(
            "div",
            { staticClass: "hook-res-modal__btn-block" },
            [
              _c(
                "CButton",
                {
                  staticClass: "w-50",
                  attrs: { color: "dark" },
                  on: { click: _vm.closeHookResModal },
                },
                [_vm._v(" " + _vm._s(_vm.$t("general.close")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }