var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    { staticClass: "h-100" },
    [
      _c(
        "CCard",
        {
          staticClass: "ccard shadow-none h-100 overflow-hidden",
          staticStyle: { marginBottom: "10px" },
          attrs: { color: "second-card-bg" },
        },
        [
          _c(
            "CCardBody",
            {
              staticClass:
                "ccard-body d-flex flex-column h-100 overflow-hidden",
            },
            [
              _vm.draftList.length
                ? [
                    _c("CDataTable", {
                      staticClass: "c-data-table h-100 overflow-hidden",
                      attrs: {
                        sorter: "",
                        fields: _vm.fields,
                        items: _vm.draftList,
                      },
                      on: { "row-clicked": _vm.rowClicked },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "type",
                            fn: function ({ item }) {
                              return [
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        !item?.group_data.is_single
                                          ? _vm.$t("general.group")
                                          : _vm.$t("general.single")
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "signal_message",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "td",
                                  [
                                    item.group_data.conditions.length
                                      ? _vm._l(
                                          item.group_data.conditions,
                                          function (v, i) {
                                            return _c(
                                              "p",
                                              { key: i, staticClass: "m-0" },
                                              [
                                                v.tf !== "price"
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "mr-1" },
                                                      [_vm._v(_vm._s(v.tf))]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "span",
                                                  { staticClass: "mr-1" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.group_data.conditionName(
                                                          v.param
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                v.hasValue
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          v.isNegative
                                                            ? v.sign
                                                            : ""
                                                        ) + _vm._s(v.value)
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        )
                                      : [_c("p", [_vm._v(" - ")])],
                                  ],
                                  2
                                ),
                              ]
                            },
                          },
                          {
                            key: "actions",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "td",
                                  { staticClass: "text-right text-nowrap" },
                                  [
                                    _c(
                                      "CLoadingButtonCustom",
                                      {
                                        attrs: {
                                          color: "danger",
                                          loading:
                                            _vm.loading &&
                                            _vm.deleteId === item.id,
                                          disabled: _vm.loading,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.deleteItem(
                                              item.id,
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("general.delete")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        119003698
                      ),
                    }),
                  ]
                : _vm._e(),
              !_vm.loading && !_vm.draftList.length
                ? _c("p", { staticClass: "m-0" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lk.notifications.emptyActiveTable")) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }