<template>
  <div class="notificationLogs">
    <CandlesGraphModal
      ref="candles"
      :rows="rows"
      :settings="settings"
      isCoinDuplicate
    />
    <div class="notificationLogs__filter">
      <!-- <span>
        {{ $t('general.show') }}:
      </span>
      <CSelect
        class="notificationLogs__filter-select"
        :value.sync="filterActive"
        :placeholder="$t('screener.treemap.parameter')"
        @change="filterActiveChange"
        :options="filterActiveOptions"
      /> -->
    </div>
    <CCard color="second-card-bg" class="shadow-none notificationLogs__card">
      <CCardBody class="h-100 d-flex flex-column">
        <div class="d-flex justify-content-between mb-4">
            <h5>
              {{ $t('lk.notifications.hookLogs') }}
            </h5>
            <span
              @click="$emit('toHowItWorks','logs')"
              class="popover-wrap d-inline-flex justify-content-center ml-2">
                {{$t('general.howItWorks')}}?
            </span>
          </div>
        <div class="table-wrap">
          <table v-if="!isLoading && tableData.length" class="table mb-0 notificationLogs__table">
            <thead>
              <tr>
                <th
                  v-for="(field, i) in fields"
                  :key="i"
                  @click="field.sortBy ? sortTable(field.sortBy) : null"
                  :class="field._classes"
                >
                {{ field.label }}
                  <span v-if="field.sortBy === orderBy" :key="sortDir">
                    {{ sortDir === 'DESC' ? '▼' : '▲' }}
                  </span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, i) in tableData" :key="item.id + '_' + i">
                <td>
                  {{ item.created_at | formatDate('DD.MM.YYYY HH:mm') }}
                </td>
                <td class="position-relative pr-3">
                  <div class="d-flex">
                    <span class="mr-2">
                      {{ item.pair }}
                    </span>
                    <button v-tooltip="{
                      content: $t('screener.openGraph'),
                      container: '.c-app',
                    }" type="button" class="cil-bar-chart-btn"
                      @click.stop="$refs.candles.openCandlesGraph(item.pair.split(':')[1], 2, item.id)">
                      <div>
                        <CIcon width="0.75rem" class="cursor-pointer" name="cil-bar-chart" />
                      </div>
                    </button>
                  </div>
                </td>
                <td>
                  <span v-html="item.signal_message">

                  </span>
                </td>
                <td>
                  <span>
                    {{ !item.is_single ? item.group_name : '-' }}
                  </span>
                </td>
                <td>
                  <span>
                    {{ item.hook.name }}
                  </span>
                </td>
                <td>
                  <span
                    class="table-link table-link-hover"
                    @click="showRes(item)"
                  >
                    {{ item.status_code }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <p v-if="!isLoading && !tableData.length" class="m-0">
            {{ $t('lk.notifications.emptyActiveTable') }}
          </p>
        </div>
        <CPagination
        class="mt-2 pagination-custom"
        :class="{_disabled: isLoading}"
        v-show="totalPages > 1"
        :activePage.sync="page"
        :pages="totalPages"
        :limit="7"
        :dots="false"
      />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import CandlesGraphModal from "@/components/screener/tables/CandlesGraphModal";
import axios from 'axios'
import settings from "@/assets/js/screenerSettings";

export default {
  name: 'NotificationLogs',
  components: {
    CandlesGraphModal
  },
  props: {
  },

  data() {
    return {
      settings: settings,
      filterActive: null,
      filterActiveOptions: [
        {
          label: this.$t('general.all'),
          value: null,
        },
        {
          label: this.$t('lk.notifications.single'),
          value: true
        },
        {
          label: this.$t('lk.notifications.group'),
          value: false
        },
      ],
      page: 1,
      totalPages: null,
      currentNotification: null,
      showInfoModal: false,
      channelConfig: {
        telegram: 'lk.notifications.target.tg',
        browser_push: 'lk.notifications.target.web'
      },
      currentStudent: null,
      orderBy: 'created_at',
      sortDir: "DESC",
      isLoading: false,
      showAppointCuratorModal: false,
      showDeleteStudentModal: false,
      selectedCuratorId: null,
      selectedStudentIds: [],
      tableData: [],
      fields: [
        {
          key: 'date',
          _classes: 'align-middle',
          label: this.$t('lk.notifications.met')
          // sortBy: 'created_at'
        },
        {
          key: 'signal_message',
          _classes: 'align-middle',
          label: this.$t('lk.notifications.coin'),
          // sortBy: 'name'
        },
        {
          key: 'signal_message',
          _classes: 'align-middle',
          label: this.$t('lk.notifications.signalMessage'),
          // sortBy: 'name'
        },
        {
          key: 'signal_message',
          _classes: 'align-middle',
          label: this.$t('lk.notifications.groupNotificationName'),
          // sortBy: 'name'
        },
        {
          key: 'name',
          label: `${this.$t('lk.training.name')} Webhook`,
          _classes: 'align-middle',
        },
        {
          key: 'hook_res',
          _classes: 'align-middle',
          label: this.$t('lk.notifications.webHookResponse'),
          // sortBy: 'name'
        },
      ],
    }
  },

  created() {
    this.getHooksLogs(/* {orderBy: this.orderBy,page: this.page} */)
  },

  computed: {
    rows() {
      return this.tableData.map(item => ({
        coin: { label: item.pair.split(':')[1] },
        id: item.id
      }))
    },
    user() {
      return this.$store.state.user.userData
    },
  },

  watch: {
    page(val, oldVal) {
      if (val && val !== oldVal) {
        this.getHooksLogs(/* {orderBy: this.orderBy} */)
      }
    }
  },
  methods: {
    showRes(item){
      this.$emit('showHookRes', item)
    },
    filterActiveChange() {
      this.getData()
    },
    getData() {
      this.getHooksLogs(/* {orderBy: this.orderBy} */)
    },
    toggleShowInfoModal(id) {
      axios
        .get(`api/v1/signals/marketplace/${id}`)
        .then((data) => {
          if (data?.data?.data) {
            this.currentNotification = new Notification(data?.data?.data)
            this.showInfoModal = true
          }
        })
    },
    sortTable(sortBy) {
      if (sortBy != this.orderBy) {
        this.orderBy = sortBy
        this.sortDir = "DESC"
      } else {
        this.sortDir == "DESC" ? this.sortDir = "ASC" : this.sortDir = "DESC"
      }
      this.getHooksLogs(/* {
        orderBy: this.orderBy,
        dir: this.sortDir
      } */)
    },
    getHooksLogs(/* params */) {
      this.isLoading = true
      this.$emit('update:loading', true)
      axios
        .get(`api/v1/signals/hooks_logs`, {
          params: {
            orderBy: this.orderBy,
            dir: this.sortDir,
            page: this.page,
            is_single: this.filterActive
          }
        })
        .then(({ data }) => {
          this.tableData = [...data.data]
          this.page = data.meta.current_page
          this.totalPages = data.meta.last_page
          this.$emit('setListCount', data.meta.total)
        })
        .finally(() => {
          this.isLoading = false
          this.$emit('update:loading', false)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.ccard-body {
  overflow: hidden;
}

.table {
  position: relative;

  thead tr th {
    background: var(--second-card-bg);
    position: sticky;
    top: -1px;
    z-index: 1;
  }
}

.notificationLogs {
  height: 75%;
  width: 100%;

  &__filter {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-bottom: 20px;

    & span {
      margin-right: 8px;
    }
  }

  &__filter-select {
    margin-bottom: 0;
  }

  &__hasMentorshipIcon {
    display: flex;
    justify-content: center;
  }

  .table-wrap {
    overflow: auto;
    width: 100%;
    flex-grow: 1;
    height: 500px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid var(--subscribe-card-bg);
    color: #20a8d8;
    padding: 12px 24px;
    align-items: flex-start;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }

    &__white {
      color: var(--white);
    }

    &-btn {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }
  }

  &__card {
    height: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-bottom: 0;
  }

  &__table {
    tbody {
      max-height: 225px;
    }
  }

  .modal-header {
    border-bottom: none;
  }
}

.table-link {
  color: var(--white);
}

.table-link-hover {
  color: var(--primary);
  cursor: pointer;

  &:hover {
    color: var(--white);
    text-decoration: underline;
  }
}

.tableHover:hover {
  cursor: pointer;
  color: var(--white);
  text-decoration: underline;
}

.cil-bar-chart-btn {
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  display: inline-flex;
  margin-left: 5px
}
.popover-wrap{
  padding: 5px 10px;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  flex-shrink: 0;
  background-color: var(--base-card-bg);
  border-radius: 6px;
}
</style>
