<template>
  <div class="notificationLogs">
    <CandlesGraphModal
      :clientId="clientId"
      ref="candles"
      :rows="rows"
      :settings="settings"
      :isFutures="candleFuturesFlag"
      isCoinDuplicate
    />
    <!-- <AppointCurratorModal
      @close="showAppointCuratorModal = false"
      @success="handleSuccess"
      :show.sync="showAppointCuratorModal"
      :eventId="eventId"
      :selectedCuratorId="selectedCuratorId"
      :selectedStudentIds="selectedStudentIds"
    />
    <DeleteStudentModal
      @close="showDeleteStudentModal = false"
      @success="handleSuccess"
      :show.sync="showDeleteStudentModal"
      :eventId="eventId"
      :selectedStudentIds="selectedStudentIds"
    />
    <EditFinishDateModal
      @close="showEditFinishDateModal = false"
      @success="handleSuccess"
      :show.sync="showEditFinishDateModal"
      v-if="showEditFinishDateModal"
      :eventId="eventId"
      :currentStudent="currentStudent"
    />
    <EditTariffModal
      @close="showEditTariffModal = false"
      @success="handleSuccess"
      :show.sync="showEditTariffModal"
      v-if="showEditTariffModal"
      :eventId="eventId"
      :currentStudent="currentStudent"
    /> -->

    <!-- <div class="notificationLogs__header">
      <span class="mb-0 mr-3" color="base-card-bg">
        <span class="notificationLogs__header__white">
          {{ $tc('lk.studentTable.selected', selectedStudentIdsLength) }}
        </span>
        {{ selectedStudentIdsLength }}
        {{ $tc('lk.studentTable.students', selectedStudentIdsLength) }}
      </span>
      <CLoadingButtonCustom
        class="ml-auto notificationLogs__header-btn"
        color="sub-btn"
        @click.native="exportToExcel()"
        >{{ $t('lk.studentTable.export') }}</CLoadingButtonCustom
      >
      <template v-if="selectedStudentIdsLength">
        <CLoadingButtonCustom
          class="notificationLogs__header-btn"
          color="primary"
          @click.native="appointManyStudents"
          >{{ $t('lk.studentTable.appointCurators') }}</CLoadingButtonCustom
        >
        <CLoadingButtonCustom
          class="notificationLogs__header-btn"
          color="danger"
          @click.native="showDeleteStudentModal = true"
          >{{ $t('lk.studentTable.delete') }}</CLoadingButtonCustom
        >
      </template>
    </div> -->
    <NotificationInfoModal
      v-if="showInfoModal"
      :show="showInfoModal"
      :currentNotification="currentNotification"
      isLog
      @editSignal="editSignal"
      @update="getData"
      @close="showInfoModal = false"
    />
    <div class="notificationLogs__controls">
      <div class="notificationLogs__filter">
        <span>{{ $t('lk.notifications.market') }}:</span>
        <CSelect
          class="notificationLogs__filter-select"
          :value.sync="typeActive"
          :placeholder="$t('screener.treemap.parameter')"
          :options="typeOptions"
          @change="filterActiveChange"
        />
      </div>
      <div class="notificationLogs__filter">
        <span>{{ $t('general.show') }}:</span>
        <CSelect
          class="notificationLogs__filter-select"
          :value.sync="filterActive"
          :placeholder="$t('screener.treemap.parameter')"
          @change="filterActiveChange"
          :options="filterActiveOptions"
        />
      </div>
      <div
        v-if="filterActive === 'group'"
        class="notificationLogs__filter --search"
      >
        <span>{{ $t('lk.notifications.notificationName') }}:</span>
        <div class="d-flex notificationLogs__search">
          <v-select-input
            class="cselect mb-0 w-50"
            v-model="searchValue"
            :options="signalList"
            :placeholder="`${$t('lk.notifications.input')}`"
            label="name"
          >
            <template #no-options>
              {{ $t('general.emptySearch') }}
            </template>
          </v-select-input>

          <!-- <CButton
            :disabled="isLoading"
            class="shadow-none ml-1"
            color="success"
            @click="getLogs(searchValue)"
          >
            <CIcon
              name="cilSearch"/>
          </CButton> -->
        </div>
      </div>
    </div>
    <CCard
      color="second-card-bg"
      class="shadow-none notificationLogs__card"
    >
      <CCardBody class="h-100 d-flex flex-column">
        <div class="table-wrap">
          <table
            v-if="!isLoading && tableData.length"
            class="table mb-0 notificationLogs__table"
          >
            <thead>
              <tr>
                <!-- <th>
                  <CInputCheckbox
                    custom
                    ref="checkboxAll"
                    color="primary-contrast"
                    @change="selectAll"
                  />
                </th> -->
                <th
                  v-for="(field, i) in fields"
                  :key="i"
                  @click="field.sortBy ? sortTable(field.sortBy) : null"
                  :class="field._classes"
                >
                  {{ field.label }}
                  <span
                    v-if="field.sortBy === orderBy"
                    :key="sortDir"
                  >
                    {{ sortDir === 'DESC' ? '▼' : '▲' }}
                  </span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(item, i) in tableData"
                :key="item.id + '_' + i"
              >
                <!-- <td>
                  <CInputCheckbox
                    custom
                    :key="i"
                    color="primary-contrast"
                    ref="checkboxTable"
                    @change="handleCheckbox(item, $event.srcElement.checked)"
                    :checked="selectedStudentIds.includes(item.id)"
                  />
                </td> -->
                <td>{{ item.created_at | formatDate('DD.MM.YYYY HH:mm') }}</td>
                <td>
                  <exchange-logo :is-futures="item.is_futures" />
                </td>
                <td class="position-relative pr-3">
                  <div class="d-flex">
                    <span class="mr-2">
                      {{ item.pair }}
                    </span>
                    <button
                      v-tooltip="{
                        content: $t('screener.openGraph'),
                        container: '.c-app',
                      }"
                      type="button"
                      class="cil-bar-chart-btn"
                      @click.stop="openCandlesGraph(item)"
                    >
                      <div>
                        <CIcon
                          width="0.75rem"
                          class="cursor-pointer"
                          name="cil-bar-chart"
                        />
                      </div>
                    </button>
                  </div>
                </td>
                <td>
                  {{
                    item.is_single ? $t('general.single') : $t('general.group')
                  }}
                </td>
                <td>{{ item.signal_message }}</td>
                <td>
                  <span
                    v-if="item.group_id && !item.is_single"
                    href=""
                    class="table-link table-link-hover"
                    @click="toggleShowInfoModal(item.group_id)"
                  >
                    {{
                      !item.is_single && item.group_name ? item.group_name : '-'
                    }}
                  </span>
                  <span v-else>
                    {{
                      !item.is_single && item.group_name ? item.group_name : '-'
                    }}
                  </span>
                </td>
                <td>
                  <span
                    v-for="(channel, index) in item.channels"
                    :key="index"
                  >
                    <span v-if="item.channels.length > 1 && index !== 0">
                      ,
                    </span>
                    {{ $t(channelConfig[channel]) }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <p
            v-if="!isLoading && !tableData.length"
            class="m-0"
          >
            {{ $t('lk.notifications.emptyActiveTable') }}
          </p>
        </div>
        <CPagination
          class="mt-2 pagination-custom"
          :class="{ _disabled: isLoading }"
          v-show="totalPages > 1"
          :activePage.sync="page"
          :pages="totalPages"
          :limit="7"
          :dots="false"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import CandlesGraphModal from '@/components/screener/tables/CandlesGraphModal'
import settings from '@/assets/js/screenerSettings'
import NotificationInfoModal from '@/components/marketplace/NotificationInfoModal'
import Notification from '@/assets/js/notifications/Notification.class'
import axios from 'axios'
import moment from 'moment'
import VSelectInput from '@/components/VSelectInput'
import ExchangeLogo from '@/components/ExchangeLogo.vue'
import {CLIENT_ID_CANDLES_BINANCE_FUTURES, CLIENT_ID_DEFAULT} from "@/shared/constants";

export default {
  name: 'NotificationLogs',
  components: {
    ExchangeLogo,
    NotificationInfoModal,
    CandlesGraphModal,
    VSelectInput,
    // AppointCurratorModal,
    // DeleteStudentModal,
    // EditFinishDateModal,
    // EditTariffModal
  },
  props: {
    // featured variable event id
    eventId: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      signalList: [],
      searchValue: '',
      typeActive: null,
      typeOptions: [
        {
          label: this.$t('lk.notifications.marketplace.types.all'),
          value: null,
        },
        {
          label: this.$t('lk.notifications.marketplace.types.futures'),
          value: 1,
        },
        {
          label: this.$t('lk.notifications.marketplace.types.spot'),
          value: 0,
        },
      ],
      filterActive: null,
      filterActiveOptions: [
        {
          label: this.$t('general.all'),
          value: null,
        },
        {
          label: this.$t('lk.notifications.single'),
          value: 'single',
        },
        {
          label: this.$t('lk.notifications.group'),
          value: 'group',
        },
      ],
      settings: settings,
      page: 1,
      totalPages: null,
      currentNotification: null,
      showInfoModal: false,
      channelConfig: {
        telegram: 'lk.notifications.target.tg',
        browser_push: 'lk.notifications.target.web',
      },
      currentStudent: null,
      showEditFinishDateModal: false,
      showEditTariffModal: false,
      orderBy: 'created_at',
      sortDir: 'DESC',
      isLoading: false,
      showAppointCuratorModal: false,
      showDeleteStudentModal: false,
      selectedCuratorId: null,
      selectedStudentIds: [],
      tableData: [],
      fields: [
        {
          key: 'date',
          _classes: 'align-middle',
          label: this.$t('lk.notifications.met'),
          // sortBy: 'created_at'
        },
        {
          key: 'is_futures',
          _classes: 'align-middle',
          label: this.$t('lk.notifications.market'),
          // sortBy: 'created_at'
        },
        {
          key: 'signal_message',
          _classes: 'align-middle',
          label: this.$t('lk.notifications.coin'),
          // sortBy: 'name'
        },
        {
          key: 'signal_message',
          _classes: 'align-middle',
          label: this.$t('lk.notifications.typeOfNotification'),
          // sortBy: 'name'
        },
        {
          key: 'signal_message',
          _classes: 'align-middle',
          label: this.$t('lk.notifications.triggerConditions'),
          // sortBy: 'name'
        },
        {
          key: 'signal_message',
          _classes: 'align-middle',
          label: this.$t('lk.notifications.groupNotificationName'),
          // sortBy: 'name'
        },
        {
          key: 'signal_message',
          _classes: 'align-middle',
          label: this.$t('lk.notifications.source'),
          // sortBy: 'name'
        },
      ],
      candleFuturesFlag: false,
    }
  },

  created() {
    this.getData(/* {orderBy: this.orderBy,page: this.page} */)
  },

  computed: {
    rows() {
      return this.tableData.map((item) => ({
        coin: { label: item.pair.split(':')[1] },
        id: item.id,
      }))
    },
    selectedStudentIdsLength() {
      return this.selectedStudentIds.length
    },
    user() {
      return this.$store.state.user.userData
    },
    clientId() {
      return this.candleFuturesFlag
        ? CLIENT_ID_CANDLES_BINANCE_FUTURES
        : CLIENT_ID_DEFAULT
    },
  },

  watch: {
    page(val, oldVal) {
      if (val && val !== oldVal) {
        this.getLogs(this.searchValue)
      }
    },
    searchValue(value) {
      this.getLogs(value)
    },
  },
  methods: {
    getSettings() {
      const getURL = this.candleFuturesFlag
        ? `/api/v3/${this.$store.getters.getLastExchange}_perp/screener/settings`
        : '/api/v1/screener/settings';

      axios.get(getURL)
        .then(res => {
          this.settings.setPairs(res?.data?.pairs)
          this.settings.setTPairs(res?.data?.tPairs)
        })
    },
    openCandlesGraph (item) {
      this.candleFuturesFlag = !!item.is_futures
      this.getSettings()
      this.$refs.candles.openCandlesGraph(item.pair, 2, item.id)
    },
    editSignal(signal) {
      this.$emit('recreateNotification', signal)
      this.showInfoModal = false
    },
    filterActiveChange() {
      this.searchValue = ''
      this.getData()
    },
    getData() {
      this.getGroupSignals()
      this.getLogs(/* {orderBy: this.orderBy} */)
    },
    toggleShowInfoModal(id) {
      axios.get(`api/v1/signals/marketplace/${id}`).then((data) => {
        if (data?.data?.data) {
          this.currentNotification = new Notification(data?.data?.data)
          this.showInfoModal = true
        }
      })
    },
    sortTable(sortBy) {
      if (sortBy !== this.orderBy) {
        this.orderBy = sortBy
        this.sortDir = 'DESC'
      } else {
        this.sortDir === 'DESC'
          ? (this.sortDir = 'ASC')
          : (this.sortDir = 'DESC')
      }
      this.getLogs(/* {
        orderBy: this.orderBy,
        dir: this.sortDir
      } */)
    },
    handleCheckbox(student, checked) {
      if (checked && !this.selectedStudentIds.includes(student.id)) {
        this.selectedStudentIds.push(student.id)
      } else {
        const index = this.selectedStudentIds.indexOf(student.id)
        if (index !== -1) {
          this.selectedStudentIds.splice(index, 1)
        }
      }
    },

    selectAll(e) {
      if (e.srcElement.checked) {
        this.tableData.forEach((student) => {
          if (!this.selectedStudentIds.includes(student.id)) {
            this.selectedStudentIds.push(student.id)
          }
        })
      } else {
        this.resetSelection()
      }
    },

    resetSelection() {
      this.selectedStudentIds = []
    },
    editTariff(student) {
      this.currentStudent = student
      this.showEditTariffModal = true
    },
    editFinishDate(student) {
      this.currentStudent = student
      this.showEditFinishDateModal = true
    },
    appointOneStudent(student) {
      this.resetSelection()
      this.selectedStudentIds.push(student.id)
      this.selectedCuratorId = student.curator?.id
      this.showAppointCuratorModal = true
    },

    // appointManyStudents() {
    //   if (this.selectedStudentIds.length) {
    //     this.selectedCuratorId = null
    //     this.showAppointCuratorModal = true
    //   }
    // },

    exportToExcel() {
      if (!this.isLoading) {
        this.isLoading = true
        axios
          .get(`/api/v2/events/${this.eventId}/users/export`, {
            responseType: 'blob',
          })
          .then((data) => {
            if (data.data) {
              let blobUrl = URL.createObjectURL(data.data)
              let linkTag = document.createElement('a')
              linkTag.href = blobUrl
              linkTag.download = `export_event_${this.eventId}_${moment().format('DD_MM_YYYY')}.xls`
              linkTag.click()
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    // handleSuccess() {
    //   this.resetSelection()
    //   this.getLogs({orderBy: this.orderBy})
    // },

    getLogs(/* params */ group_name = '') {
      this.isLoading = true
      this.$emit('update:loading', true)
      axios
        .get(`api/v1/signals/logs`, {
          params: {
            is_futures: this.typeActive,
            orderBy: this.orderBy,
            dir: this.sortDir,
            page: this.page,
            is_single:
              this.filterActive === 'single'
                ? true
                : this.filterActive === 'group'
                  ? false
                  : undefined,
            group_name,
          },
        })
        .then(({ data }) => {
          this.tableData = [...data.data]
          this.page = data.meta.current_page
          this.totalPages = data.meta.last_page
          this.$emit('setListCount', data.meta.total)
        })
        .finally(() => {
          this.isLoading = false
          this.$emit('update:loading', false)
        })
    },
    getGroupSignals() {
      axios.get(`api/v1/signals/logs/group_names`, {params: {
          is_futures: this.typeActive,
        }}).then(({ data }) => {
        this.signalList = data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ccard-body {
  overflow: hidden;
}

.table {
  position: relative;

  thead tr th {
    background: var(--second-card-bg);
    position: sticky;
    top: -1px;
    z-index: 1;
  }
}

.notificationLogs {
  height: 75%;
  width: 100%;

  &__filter {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-bottom: 20px;

    & span {
      margin-right: 8px;
    }
    &.--search {
      flex-grow: 1;
    }
  }
  &__search {
    flex-grow: 1;
    min-width: 180px;
  }

  &__filter-select {
    margin-bottom: 0;
  }

  &__hasMentorshipIcon {
    display: flex;
    justify-content: center;
  }

  .table-wrap {
    overflow: auto;
    width: 100%;
    flex-grow: 1;
    height: 500px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid var(--subscribe-card-bg);
    color: #20a8d8;
    padding: 12px 24px;
    align-items: flex-start;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }

    &__white {
      color: var(--white);
    }

    &-btn {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }
  }

  &__card {
    height: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-bottom: 0;
  }

  &__table {
    tbody {
      max-height: 225px;
    }
  }

  .modal-header {
    border-bottom: none;
  }
  &__controls {
    display: flex;
    column-gap: 10px;
    flex-wrap: wrap;
  }
}

.table-link {
  color: var(--white);
}

.table-link-hover {
  color: var(--primary);
  cursor: pointer;

  &:hover {
    color: var(--white);
    text-decoration: underline;
  }
}

.tableHover:hover {
  cursor: pointer;
  color: var(--white);
  text-decoration: underline;
}

.cil-bar-chart-btn {
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  display: inline-flex;
  margin-left: 5px;
}

.cselect {
  max-width: 300px;
  flex-grow: 1;
  /deep/ .vs__selected-options {
    flex-wrap: nowrap;
    margin-right: 0.5rem;
  }
  /deep/ .vs__selected {
    white-space: nowrap;
  }
}
</style>
