import { render, staticRenderFns } from "./HooksTable.vue?vue&type=template&id=339a3996&scoped=true&"
import script from "./HooksTable.vue?vue&type=script&lang=js&"
export * from "./HooksTable.vue?vue&type=script&lang=js&"
import style0 from "./HooksTable.vue?vue&type=style&index=0&id=339a3996&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "339a3996",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/user0/cvizor/cvizor-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('339a3996')) {
      api.createRecord('339a3996', component.options)
    } else {
      api.reload('339a3996', component.options)
    }
    module.hot.accept("./HooksTable.vue?vue&type=template&id=339a3996&scoped=true&", function () {
      api.rerender('339a3996', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/notifications/HooksTable.vue"
export default component.exports