var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notificationLogs" },
    [
      _c("CandlesGraphModal", {
        ref: "candles",
        attrs: {
          clientId: _vm.clientId,
          rows: _vm.rows,
          settings: _vm.settings,
          isFutures: _vm.candleFuturesFlag,
          isCoinDuplicate: "",
        },
      }),
      _vm.showInfoModal
        ? _c("NotificationInfoModal", {
            attrs: {
              show: _vm.showInfoModal,
              currentNotification: _vm.currentNotification,
              isLog: "",
            },
            on: {
              editSignal: _vm.editSignal,
              update: _vm.getData,
              close: function ($event) {
                _vm.showInfoModal = false
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "notificationLogs__controls" }, [
        _c(
          "div",
          { staticClass: "notificationLogs__filter" },
          [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("lk.notifications.market")) + ":"),
            ]),
            _c("CSelect", {
              staticClass: "notificationLogs__filter-select",
              attrs: {
                value: _vm.typeActive,
                placeholder: _vm.$t("screener.treemap.parameter"),
                options: _vm.typeOptions,
              },
              on: {
                "update:value": function ($event) {
                  _vm.typeActive = $event
                },
                change: _vm.filterActiveChange,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "notificationLogs__filter" },
          [
            _c("span", [_vm._v(_vm._s(_vm.$t("general.show")) + ":")]),
            _c("CSelect", {
              staticClass: "notificationLogs__filter-select",
              attrs: {
                value: _vm.filterActive,
                placeholder: _vm.$t("screener.treemap.parameter"),
                options: _vm.filterActiveOptions,
              },
              on: {
                "update:value": function ($event) {
                  _vm.filterActive = $event
                },
                change: _vm.filterActiveChange,
              },
            }),
          ],
          1
        ),
        _vm.filterActive === "group"
          ? _c("div", { staticClass: "notificationLogs__filter --search" }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("lk.notifications.notificationName")) + ":"
                ),
              ]),
              _c(
                "div",
                { staticClass: "d-flex notificationLogs__search" },
                [
                  _c("v-select-input", {
                    staticClass: "cselect mb-0 w-50",
                    attrs: {
                      options: _vm.signalList,
                      placeholder: `${_vm.$t("lk.notifications.input")}`,
                      label: "name",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "no-options",
                          fn: function () {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("general.emptySearch")) +
                                  " "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3767542590
                    ),
                    model: {
                      value: _vm.searchValue,
                      callback: function ($$v) {
                        _vm.searchValue = $$v
                      },
                      expression: "searchValue",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "CCard",
        {
          staticClass: "shadow-none notificationLogs__card",
          attrs: { color: "second-card-bg" },
        },
        [
          _c(
            "CCardBody",
            { staticClass: "h-100 d-flex flex-column" },
            [
              _c("div", { staticClass: "table-wrap" }, [
                !_vm.isLoading && _vm.tableData.length
                  ? _c(
                      "table",
                      { staticClass: "table mb-0 notificationLogs__table" },
                      [
                        _c("thead", [
                          _c(
                            "tr",
                            _vm._l(_vm.fields, function (field, i) {
                              return _c(
                                "th",
                                {
                                  key: i,
                                  class: field._classes,
                                  on: {
                                    click: function ($event) {
                                      field.sortBy
                                        ? _vm.sortTable(field.sortBy)
                                        : null
                                    },
                                  },
                                },
                                [
                                  _vm._v(" " + _vm._s(field.label) + " "),
                                  field.sortBy === _vm.orderBy
                                    ? _c("span", { key: _vm.sortDir }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.sortDir === "DESC" ? "▼" : "▲"
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.tableData, function (item, i) {
                            return _c("tr", { key: item.id + "_" + i }, [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDate")(
                                      item.created_at,
                                      "DD.MM.YYYY HH:mm"
                                    )
                                  )
                                ),
                              ]),
                              _c(
                                "td",
                                [
                                  _c("exchange-logo", {
                                    attrs: { "is-futures": item.is_futures },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "td",
                                { staticClass: "position-relative pr-3" },
                                [
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c("span", { staticClass: "mr-2" }, [
                                      _vm._v(" " + _vm._s(item.pair) + " "),
                                    ]),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: {
                                              content:
                                                _vm.$t("screener.openGraph"),
                                              container: ".c-app",
                                            },
                                            expression:
                                              "{\n                      content: $t('screener.openGraph'),\n                      container: '.c-app',\n                    }",
                                          },
                                        ],
                                        staticClass: "cil-bar-chart-btn",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.openCandlesGraph(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("CIcon", {
                                              staticClass: "cursor-pointer",
                                              attrs: {
                                                width: "0.75rem",
                                                name: "cil-bar-chart",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.is_single
                                        ? _vm.$t("general.single")
                                        : _vm.$t("general.group")
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.signal_message))]),
                              _c("td", [
                                item.group_id && !item.is_single
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "table-link table-link-hover",
                                        attrs: { href: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleShowInfoModal(
                                              item.group_id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              !item.is_single && item.group_name
                                                ? item.group_name
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            !item.is_single && item.group_name
                                              ? item.group_name
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]),
                              _c(
                                "td",
                                _vm._l(
                                  item.channels,
                                  function (channel, index) {
                                    return _c("span", { key: index }, [
                                      item.channels.length > 1 && index !== 0
                                        ? _c("span", [_vm._v(" , ")])
                                        : _vm._e(),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(_vm.channelConfig[channel])
                                          ) +
                                          " "
                                      ),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    )
                  : _vm._e(),
                !_vm.isLoading && !_vm.tableData.length
                  ? _c("p", { staticClass: "m-0" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("lk.notifications.emptyActiveTable")) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("CPagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.totalPages > 1,
                    expression: "totalPages > 1",
                  },
                ],
                staticClass: "mt-2 pagination-custom",
                class: { _disabled: _vm.isLoading },
                attrs: {
                  activePage: _vm.page,
                  pages: _vm.totalPages,
                  limit: 7,
                  dots: false,
                },
                on: {
                  "update:activePage": function ($event) {
                    _vm.page = $event
                  },
                  "update:active-page": function ($event) {
                    _vm.page = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }