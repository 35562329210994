<template>
  <CModal
    centered
    v-if="show"
    :show="show"
    @update:show="$emit('close')"
    class="hook-res-modal"
  >
    <template #header>
      <div class="hook-res-modal__header">
        <h5 class="hook-res-modal__title">
          {{ currentRes.status_code }}
        </h5>
        <CIcon
        class="hook-res-modal__header-close"
        name="cil-x"
        @click.native="closeHookResModal" />
      </div>
    </template>
    <CTextarea
      disabled
      rows="10"
      class="mb-0 w-100"
      v-model="currentRes.response_body"
    />
    <div class="hook-res-modal__btn-block">
      <CButton
        class="w-50"
        color="dark"
        @click="closeHookResModal">
        {{$t('general.close')}}
      </CButton>
    </div>
    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>
export default {
  name: "HookResModal",
  components: {
  },
  props:{
    currentRes:{
      type: Object,
      default: () => ({})
    },
    show: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return{
    }
  },
  created () {
  },
  destroyed () {
  },
  mounted(){
  },
  watch: {
  },
  computed:{
  },
  methods:{
    closeHookResModal(){
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.hook-res-modal{
  &__header{
    position: relative;
    display: flex;
    width: 100%;
    &-close{
      position: absolute;
      cursor: pointer;
      user-select: none;
      right: 0;
      &:hover {
        color: $danger;
      }
    }
  }
  &__title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__switch{
    margin-left: 15px;
    margin-right: 30px;
    flex-shrink: 0;
  }
  &__btn-block{
    display: flex;
    column-gap: 10px;
    margin-top: 20px;
  }
}
</style>
