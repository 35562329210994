
<template>
  <div class="notifications d-flex flex-column">
    <div class="header">
      <h1 class="title mb-0">{{ $t('lk.notifications.title') }}</h1>

      <CSpinner
        v-if="loading"
        color="primary"
        size="sm"
        class="mx-2"
      />

      <CButton
        class="cbtn px-4"
        color="primary"
        @click="handleAddNotification"
      >
        <CIcon name="cil-plus"/>
        {{ $t('lk.notifications.addBtn') }}
      </CButton>
    </div>
    <div
      v-if="tabList.length > 1"
      class="notifications__controls"
    >
      <time-range-selector
        :hideTitle="true"
        :options="tabList"
        v-model="selectedTab"
        class="notifications__tabSelector"
      />
      <CSpinner
        v-if="selectedTab === 1 && loading"
        color="primary"
        size="sm"
        class="mx-2"
      />
      <div
        class="d-flex">
        <template  v-if="selectedTab === 1 && !loading">
          <CButton
            v-if="showPlayButton"
            :color="isPaused ? 'warning' : 'primary'"
            :disabled="loading"
            class="shadow-none mr-2"
            @click="togglePause"
          >
            <CIcon
              :name="isPaused ? 'cil-media-play' : 'cil-media-pause'"
            />
          </CButton>
          <TokensBlock
            :tokens="tokens"
            inLkList/>
        </template>
        <div
          v-if="[0, 3].includes(selectedTab)">
          <span
            v-if="selectedTab === 0"
            class="mr-3">
            {{ $t('lk.notifications.notifications') }} : {{ listCount }}
          </span>
          <CButton
            color='danger'
            :disabled="loading || !listCount"
            @click="showConfirmClearLogsModal = true"
          >
            {{ selectedTab === 0 ? $t('general.deleteAll') : $t('general.clearLogs') }}
          </CButton>
        </div>
      </div>
    </div>

    <confirmModal
      :show="showConfirmClearLogsModal"
      :title="(selectedTab === 0 ? $t('general.deleteAll') : $t('general.clearLogs')).toString()"
      @confirm="clearLogs"
      @close="showConfirmClearLogsModal = false"
    >
      {{ $t('lk.notifications.deleteNotificationText') }}
    </confirmModal>

    <hook-modal
      :currentHook="currentHook"
      :show="showCreateHookModal"
      :is-futures="notification.is_futures"
      @saveHook="saveHook"
      @close="closeHookModal"
    />

    <notification-modal
      v-if="showModal"
      :notification="notification"
      :showModal="showModal"
      :isFuturesForRerender.sync="isFuturesForRerender"
      @close="closeEvent"
      @getNotifications="getNotifications"
    />

    <hook-res-modal
      :show="showHookResModal"
      :currentRes="currentRes"
      @close="closeHookResModal"
    />
    <notifications-table
      v-if="selectedTab === 0"
      ref="table"
      :loading.sync="loading"
      @setListCount="setListCount"
      @rowClicked="openModal"
    />
    <GroupNotifications
      :loading.sync="loading"
      ref="groupNotifications"
      @updatePaused="setIsPaused"
      @setTokens="setTokens"
      @setIsPaused="setIsPaused"
      @recreateNotification="openModal"
      v-if="selectedTab === 1"/>
    <AdminGroupNotifications
      :loading.sync="loading"
      ref="groupNotificationsAdmin"
      v-if="selectedTab === 2"/>
    <NotificationLogs
      :loading.sync="loading"
      ref="notificationLogs"
      @recreateNotification="openModal"
      @setListCount="setListCount"
      v-if="selectedTab === 3"/>
    <NotificationDrafts
      :loading.sync="loading"
      ref="notificationDrafts"
      @rowClicked="openModal"
      v-if="selectedTab === 4"/>
    <hooks-table
      v-if="selectedTab === 5"
      ref="hooksTable"
      :loading.sync="loading"
      @recreateNotification="openModal"
      @setListCount="setListCount"
      @rowClicked="openModal"
      @toHowItWorks="toHowItWorks"
      @createHook="createHook"
    />
    <hooks-logs-table
      v-if="selectedTab === 6"
      ref="hooksLogsTable"
      :loading.sync="loading"
      @setListCount="setListCount"
      @rowClicked="openModal"
      @showHookRes="showHookRes"
      @toHowItWorks="toHowItWorks"
    />
  </div>
</template>

<script>
import HookModal from '@/components/notifications/HookModal.vue';
import HookResModal from "@/components/notifications/HookResModal";
import axios from 'axios'
import TokensBlock from '@/components/marketplace/TokensBlock'
import NotificationDrafts from "@/components/notifications/NotificationDrafts.vue";
import NotificationLogs from "@/components/notifications/NotificationLogs.vue";
import AdminGroupNotifications from "@/components/notifications/AdminGroupNotifications";
import GroupNotifications from "@/components/notifications/GroupNotifications.vue";
import TimeRangeSelector from '@/components/training/tabSelector.vue'
import NotificationsTable from "@/components/notifications/NotificationsTable.vue";
import HooksTable from "@/components/notifications/HooksTable";
import HooksLogsTable from "@/components/notifications/HooksLogsTable";
import Condition from "@/assets/js/notifications/Condition.class";
import Notification from "@/assets/js/notifications/Notification.class";
import NotificationModal from "@/components/notifications/NotificationModal.vue";
import { cloneDeep } from "lodash";
import confirmModal from '@/components/common/confirmModal.vue';
import hideSupportMixin from "@/mixins/hideSupportMixin"
import Hook from "@/assets/js/notifications/Hook.class";

const initialNotify = new Notification({conditions: [new Condition()]})

export default {
  name: "Notifications",
  components: {
    NotificationDrafts,
    NotificationLogs,
    NotificationsTable,
    NotificationModal,
    TimeRangeSelector,
    GroupNotifications,
    AdminGroupNotifications,
    TokensBlock,
    confirmModal,
    HooksTable,
    HooksLogsTable,
    HookResModal,
    HookModal
  },
  mixins: [hideSupportMixin],
  data() {
    return {
      currentHook: null,
      showCreateHookModal: false,
      showHookResModal: false,
      currentRes: null,
      listCount: 0,
      showPlayButton: false,
      isPaused: false,
      tokens: [],
      selectedTab: 0,
      btnOptions: [
        {
          label: this.$t(`lk.notifications.single`),
          key: 0,
          iconName: 'cil-user'
        },
        {
          label: this.$t(`general.logs`),
          key: 3,
          iconName: 'cil-spreadsheet'
        },
        {
          label: this.$t(`lk.notifications.drafts`),
          key: 4,
          iconName: 'cilDescription'
        },
        {
          label: this.$t(`lk.notifications.hooks`),
          key: 5,
          iconName: 'cilListRich'
        },
        {
          label: this.$t(`lk.notifications.hookLogs`),
          key: 6,
          iconName: 'cil-spreadsheet'
        },
      ],
      showModal: false,
      showConfirmClearLogsModal: false,
      notification: initialNotify.copy(),
      loading: true,
      isFuturesForRerender: false,
    }
  },
  watch: {
    isFuturesForRerender() {
      this.closeEvent()
      this.$nextTick(() => {
        this.handleAddNotification()
      })
    },
  },
  created(){
    const lsDraftData = JSON.parse(localStorage.getItem('draftData'))
    if(lsDraftData){
      this.notification = new Notification(lsDraftData)
      this.showModal = true
      localStorage.removeItem('draftData')
    }
  },
  methods: {
    async saveHook(item){
      try{
      // TODO change method
        const hookInst = new Hook(item)
        const {status} =  await axios.post(`api/v2/hook_templates`,{
          ...hookInst
        })
        if (status < 300) {
          this.$refs.hooksTable.getHooks()
        }
      }
      finally{
        this.closeHookModal()
      }
    },
    closeHookModal(){
      this.currentHook = null
      this.showCreateHookModal = false
    },
    createHook(){
      this.currentHook = new Hook({editMode: true, enable: true})
      this.showCreateHookModal = true
    },
    toHowItWorks(isLogs){
      let url
      if(isLogs){
        url = this.locale === 'ru' ?
        'https://docs.cvizor.com/en/v/ru/modules/signals/webhooks-log' :
        'https://docs.cvizor.com/en/modules/signals/webhook-logs'
      }else{
        url = this.locale === 'ru' ?
        'https://docs.cvizor.com/en/v/ru/modules/signals/webhooks ' :
        'https://docs.cvizor.com/en/modules/signals/webhook'
      }
      window.open(url, '_blank');
    },
    closeHookResModal(){
      this.currentRes = null
      this.showHookResModal = false
    },
    showHookRes(item){
      this.currentRes = item
      this.showHookResModal = true
    },
    setListCount(count){
      this.listCount = count
    },
    async clearLogs(){
      try{
        this.loading = true
        if(this.selectedTab === 3){
          await axios.delete(`/api/v1/signals/logs`)
        }
        if(this.selectedTab === 0){
          await axios.delete('api/v1/signals')
        }
        this.getNotifications()
      }finally{
        this.loading = false
        this.showConfirmClearLogsModal = false
      }
    },
    setIsPaused(list){
      const filteredList = list.filter(item => item.is_subscribed || item.byUser)
      if(!filteredList.length){
        this.showPlayButton = false
        return
      }
      this.showPlayButton = true
      this.isPaused = filteredList.every(group => group.isPaused)
    },
    togglePause(){
      axios.post('api/v1/signals/marketplace/enable-all-signals', {enable: !!this.isPaused})
        // .then(({status, data}) => {
        //   if (status < 300) {
        //     this.groupNotifications = data.data.map(v => new Notification(v))
        //   }
        // })
        .finally(() => {
          this.getNotifications()
        })
    },
    setTokens(tokens){
      this.tokens = tokens
    },
    closeEvent() {
      this.showModal = false
      this.notification = initialNotify.copy()
    },
    openModal(notify, isFutures) {
      this.notification = cloneDeep(notify) || initialNotify.copy()
      if (isFutures !== undefined) {
        this.notification.is_futures_init = isFutures
        this.notification.is_futures = isFutures
      }
      this.showModal = true
    },
    handleAddNotification() {
      this.openModal(null, this.isFuturesForRerender)
    },
    getNotifications() {
      if (this.selectedTab === 0) this.$refs.table.getNotifications()
      if (this.selectedTab === 1) this.$refs.groupNotifications.getData()
      if (this.selectedTab === 2) this.$refs.groupNotificationsAdmin.getNotifications()
      if (this.selectedTab === 3) this.$refs.notificationLogs.getData()
      if (this.selectedTab === 4) this.$refs.notificationDrafts.getDrafts()
      if (this.selectedTab === 5) this.$refs.hooksTable.getHooks()
      if (this.selectedTab === 6) this.$refs.hooksLogsTable.getHooksLogs()
    },
  },
  computed: {
    locale() {
      return this.$root.$i18n.locale
    },
    isAdmin() {
      return this.$store.getters['user/getPermissions']('control-education')
    },
    tabList() {
      const data = [...this.btnOptions]
      if(this.$store.getters['user/getTariffPermissions']('group_notifications')) data.push(
        {
          label: this.$t(`lk.notifications.group`),
          key: 1,
          iconName: 'cil-group'
        },
        /* {
          label: this.$t(`lk.notifications.drafts`),
          key: 4,
          iconName: 'cilDescription'
        } */)
      if(this.isAdmin) data.push({
        label: this.$t(`marketplace.title`),
        key: 2,
        iconName: 'cil-lock-locked'
      })
      return data.sort((a, b) => a.key - b.key)
    },
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;

  .cbtn {
    margin-left: auto;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 1.25rem;

    .title {
      display: none;
    }
    .cbtn {
      width: 100%;
    }
  }
  @include media-breakpoint-up(sm) {
    margin-bottom: 1.75rem;

    .title {
      font-size: 1.25rem;
    }
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 2.25rem;

    .title {
      font-size: 2.25rem;
    }
  }
}

.notifications {
  &__controls{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    gap: 15px;
    @include media-breakpoint-up(md) {
      margin-bottom: 18px;
    }
  }
  &__tabSelector {
    /deep/ .btn-group {
      flex-direction: row;
    }

    /deep/ .button-custom {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 18px;
      column-gap: 5px;
      flex-shrink: 0;
      max-width: 160px
    }
  }

  @include media-breakpoint-down(md) {
    @media (min-height: 450px) {
      height: 100%;
    }
  }

  @include media-breakpoint-down(lg) {
    @media (min-height: 500px) {
      height: 100%;
    }
  }

  @include media-breakpoint-up(xl) {
    @media (min-height: 550px) {
      height: 100%;
    }
  }
}
</style>
