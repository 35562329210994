<template>
  <div>
    <CandlesGraphModal
      :clientId="clientId"
      ref="candles"
      :rows="rows"
      :settings="settings"
      :isCoinDuplicate="true"
      :isFutures="candleFuturesFlag"
    />
    <CCard
      color="second-card-bg"
      class="ccard shadow-none overflow-hidden"
    >
      <CCardBody class="ccard-body d-flex flex-column overflow-hidden">
        <div class="mb-4 d-flex align-items-center">
          <h5>
            {{ $t('lk.notifications.activeListTitle') }}
          </h5>
          <CSelect
            class="mb-0 ml-3"
            :value.sync="typeActive"
            :placeholder="$t('screener.treemap.parameter')"
            :options="typeOptions"
            @change="getNotifications"
          />
        </div>
        <template v-if="activeItems && activeItems.length">
          <CDataTable
            sorter
            :fields="fields"
            :items="activeItems"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @filtered-items-change="tableSortHandler"
            class="c-data-table h-100 overflow-hidden"
            @row-clicked="rowClicked"
          >
            <template #name="{ item }">
              <td>
                <div class="d-flex">
                  <span class="mr-2">
                    {{ item.pair.label }}
                  </span>
                  <button
                    v-tooltip="{
                      content: $t('screener.openGraph'),
                      container: '.c-app',
                    }"
                    type="button"
                    class="cil-bar-chart-btn"
                    @click.stop="openCandlesGraph(item)"
                  >
                    <div>
                      <CIcon
                        width="0.75rem"
                        class="cursor-pointer"
                        name="cil-bar-chart"
                      />
                    </div>
                  </button>
                </div>
              </td>
            </template>

            <template #is_futures="{ item }">
              <td>
                <exchange-logo :is-futures="item.is_futures" />
              </td>
            </template>

            <template #conditions="{ item }">
              <td>
                <p
                  v-for="(v, i) in item.conditions"
                  :key="i"
                  class="m-0"
                >
                  <span
                    v-if="v.tf !== 'price'"
                    class="mr-1"
                  >
                    {{ v.tf }}
                  </span>
                  <span class="mr-1">{{ item.conditionName(v.param) }}</span>
                  <span v-if="v.hasValue">
                    {{ v.isNegative ? v.sign : '' }}{{ v.value }}
                  </span>
                </p>
              </td>
            </template>
            <template #repeatableObj="{ item }">
              <td>
                {{
                  item.repeatableObj.labelI18n
                    ? $t(item.repeatableObj.labelI18n)
                    : item.repeatableObj.label
                }}
              </td>
            </template>
            <template #unixCreatedDate="{ item }">
              <td>
                {{ item.createdAt }}
              </td>
            </template>
            <template #actions="{ item }">
              <td class="text-right text-nowrap">
                <CButton
                  class="mr-2"
                  color="success"
                  @click.stop="editSignal(item)"
                >
                  <CIcon name="cil-pencil" />
                </CButton>
                <CLoadingButtonCustom
                  color="danger"
                  :loading="checkLoadingId(item)"
                  :disabled="checkLoadingId(item)"
                  @click.stop.native="deleteItem(item, $event)"
                >
                  <CIcon name="cil-trash" />
                </CLoadingButtonCustom>
              </td>
            </template>
          </CDataTable>
        </template>
        <p
          v-else
          class="m-0"
        >
          {{ $t('lk.notifications.emptyActiveTable') }}
        </p>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import moment from 'moment'
import settings from '@/assets/js/screenerSettings'
import CandlesGraphModal from '@/components/screener/tables/CandlesGraphModal.vue'
import axios from 'axios'
import Notification from '@/assets/js/notifications/Notification.class'
import debounce from 'lodash/debounce'
import ExchangeLogo from '@/components/ExchangeLogo.vue'
import {CLIENT_ID_CANDLES_BINANCE_FUTURES, CLIENT_ID_DEFAULT} from "@/shared/constants";
export default {
  name: 'NotificationsTable',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ExchangeLogo,
    CandlesGraphModal,
  },
  data() {
    return {
      settings: settings,
      Notification,
      items: [],
      typeActive: null,
      typeOptions: [
        {
          label: this.$t('lk.notifications.marketplace.types.all'),
          value: null,
        },
        {
          label: this.$t('lk.notifications.marketplace.types.futures'),
          value: 1,
        },
        {
          label: this.$t('lk.notifications.marketplace.types.spot'),
          value: 0,
        },
      ],
      fields: [
        { key: 'name', label: this.$t('lk.notifications.coin') },
        {
          key: 'is_futures',
          label: this.$t('lk.notifications.market'),
          sorter: false,
        },
        {
          key: 'conditions',
          label: this.$t('lk.notifications.typeOfNotification'),
          sorter: false,
        },
        {
          key: 'repeatableObj',
          label: this.$t('lk.notifications.repeat.title'),
          sorter: false,
        },
        { key: 'unixCreatedDate', label: this.$t('lk.notifications.create') },
        { key: 'metAt', label: this.$t('lk.notifications.met') },
        { key: 'actions', label: '' },
      ],
      sortBy: '',
      sortDesc: false,
      loadingDel: [],
      candleFuturesFlag: false,
    }
  },
  computed: {
    rows() {
      return this.activeItems.map((item) => ({
        coin: { label: item.pair.symbol },
        id: item.pair.id,
      }))
    },
    activeItems: {
      get() {
        const data = []
        this.items.forEach((item) => {
          if (item.enable) {
            item.name = item.pair
              ? `${item.pair.exchange}:${item.pair.symbol}`
              : '-'
            item.unixCreatedDate = moment(
              item.createdAt,
              'DD.MM.YYYY HH:mm',
            ).unix()
            data.push(item)
          }
        })

        this.$emit('setListCount', data.length)
        return data
      },
      set(list) {
        this.items = list
      },
    },
    // inActiveItems() {
    //   const data = []
    //   this.items.forEach((item) => {
    //     if (!item.enable) {
    //       item.name = item.pair ? `${item.pair.exchange}:${item.pair.symbol}` : '-'
    //       data.push(item)
    //     }
    //   })
    //   return data
    // },

    clientId() {
      return this.candleFuturesFlag
        ? CLIENT_ID_CANDLES_BINANCE_FUTURES
        : CLIENT_ID_DEFAULT
    },
  },
  created() {
    this.getNotifications()
  },
  methods: {
    openCandlesGraph (item) {
      this.candleFuturesFlag = !!item.is_futures
      this.$refs.candles.openCandlesGraph(item.pair.label, 2, item.pair.id)
    },
    tableSortHandler(sortedList) {
      this.activeItems = sortedList
    },
    getNotifications() {
      this.$emit('update:loading', true)

      axios
        .get('api/v1/signals/single-list', {
          params: {
            is_futures: this.typeActive,
          },
        })
        .then(({ status, data }) => {
          if (status < 300) {
            this.items = data.data.map((v) => new Notification(v))
          }
        })
        .finally(() => {
          this.$emit('update:loading', false)
        })
    },
    debounceGetNotifications: debounce(function () {
      return this.getNotifications()
    }, 1500),
    deleteItem(notification, e) {
      this.loadingDel.push(notification.id)
      e.target.blur()
      notification
        .delete()
        .then(({ status }) => {
          if (status < 300) {
            this.items = this.items.filter((el) => el.id !== notification.id)
            this.debounceGetNotifications()
          }
        })
        .finally(() => {
          this.loadingDel = this.loadingDel.filter(
            (id) => id !== notification.id,
          )
        })
    },
    rowClicked(notify) {
      this.$emit('rowClicked', notify)
    },
    editSignal(notify) {
      notify.editMode = true
      this.rowClicked(notify)
    },
    checkLoadingId(item) {
      return this.loadingDel.some((id) => id === item.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.ccard {
  @include media-breakpoint-down(xs) {
    .ccard-body {
      padding: 1rem 1.75rem;
    }
  }

  @include media-breakpoint-down(sm) {
    margin: 0 -1.25rem;
  }

  @include media-breakpoint-up(sm) {
    margin: 0 -2rem;

    .ccard-body {
      padding: 1.25rem 1.75rem;
    }
  }

  @include media-breakpoint-up(xl) {
    margin: 0;
  }
}

.c-data-table {
  /deep/ {
    tbody {
      tr {
        cursor: pointer;
      }
    }

    thead {
      th {
        background: var(--second-card-bg);
        position: sticky;
        top: -1px;
        z-index: 1;
      }
    }

    .table-responsive {
      height: 100%;
    }
  }
}

.cil-bar-chart-btn {
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  display: inline-flex;
  margin-left: auto;
}
</style>
